import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Item } from './Item';
import {Box} from "@mui/material";


interface DraggableItemProps {
    item: Item;
    index: number;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ item, index }) => {
    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`draggable-item ${snapshot.isDragging ? 'is-dragging' : ''}`}>
                    <div className="info">{index + 1}</div>{' '}
                    <div className="info">{item.name}</div>{' '}
                    <div className="info">{item.lastYearPlacement}</div>{' '}
                    <div className="info">{item.lastYearsNumberOfPoints}</div>
                </Box>
            )}
        </Draggable>
    );
};
export default DraggableItem;