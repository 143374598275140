import * as React from "react";
import { render } from "react-dom";
import App from "./App";
class Componenet extends React.Component {
    render() {
        return (
            <div>
                <App/>
            </div>
        );
    }
}

render(<Componenet />, document.getElementById("root"));