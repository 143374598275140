export interface Item {
    id: string;
    name: string;
    lastYearPlacement: string;
    lastYearsNumberOfPoints: string;
}

export function AllTeams(): Item[]{
    return [
        {
            id: '1',
            name: 'Bodø/Glimt',
            lastYearPlacement: "1",
            lastYearsNumberOfPoints: "70"
        },
        {
            id: '2',
            name: 'Brann',
            lastYearPlacement: "2",
            lastYearsNumberOfPoints: "61"
        },
        {
            id: '3',
            name: 'Tromsø',
            lastYearPlacement: "3",
            lastYearsNumberOfPoints: "61"
        },
        {
            id: '4',
            name: 'Viking',
            lastYearPlacement: "4",
            lastYearsNumberOfPoints: "58"
        },
        {
            id: '5',
            name: 'Molde',
            lastYearPlacement: "5",
            lastYearsNumberOfPoints: "51"
        },
        {
            id: '6',
            name: 'Lillestrøm',
            lastYearPlacement: "6",
            lastYearsNumberOfPoints: "43"
        },
        {
            id: '7',
            name: 'Strømsgodset IF',
            lastYearPlacement: "7",
            lastYearsNumberOfPoints: "42"
        },
        {
            id: '8',
            name: 'Sarpsborg 08',
            lastYearPlacement: "8",
            lastYearsNumberOfPoints: "41"
        },
        {
            id: '9',
            name: 'Rosenborg',
            lastYearPlacement: "9",
            lastYearsNumberOfPoints: "39"
        },
        {
            id: '10',
            name: 'Odd',
            lastYearPlacement: "10",
            lastYearsNumberOfPoints: "38"
        },
        {
            id: '11',
            name: 'HamKam',
            lastYearPlacement: "11",
            lastYearsNumberOfPoints: "34"
        },
        {
            id: '12',
            name: 'FK Haugesund',
            lastYearPlacement: "12",
            lastYearsNumberOfPoints: "33"
        },
        {
            id: '13',
            name: 'Sandefjord Fotball',
            lastYearPlacement: "13",
            lastYearsNumberOfPoints: "31"
        },
        {
            id: '14',
            name: 'Fredrikstad',
            lastYearPlacement: "1(OBOS)",
            lastYearsNumberOfPoints: "64"
        },
        {
            id: '15',
            name: 'KFUM Oslo',
            lastYearPlacement: "2(OBOS)",
            lastYearsNumberOfPoints: "58"
        },
        {
            id: '16',
            name: 'Kristiansund BK',
            lastYearPlacement: "4(OBOS)",
            lastYearsNumberOfPoints: "50"
        }
    ];
}