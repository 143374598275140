import React from 'react';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import DraggableList from './components/Table/DraggableList';
import './App.css';
import {AllTeams} from "./components/Table/Item";
import AppBar from "./components/AppBar";
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    const [items, setItems] = React.useState(AllTeams());

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    };

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <AppBar/>
                <DragDropContext onDragEnd={onDragEnd}>
                    <DraggableList items={items}/>
                </DragDropContext>
            </ThemeProvider>
        </div>
    );
};

export default App;