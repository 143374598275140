import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableItem from './DraggableItem';
import { Item } from './Item';
import {Box, Container} from "@mui/material";

interface DraggableListProps {
    items: Item[];
}

const DraggableList: React.FC<DraggableListProps> = ({ items }) => {
    return (
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <Container
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`droppable-area ${snapshot.isDraggingOver ? 'is-dragging-over' : ''}`}>
                    <Box className="draggable-item">
                        <div className="info">#</div>
                        <div className="info">Lagnavn</div>
                        <div className="info">Plassering 2023</div>
                        <div className="info">Poeng 2023</div>
                    </Box>
                    {items.map((item, index) => (
                        <DraggableItem key={item.id} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                </Container>
            )}
        </Droppable>
    );
};

export default DraggableList;