import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../images/tabelltipset-high-resolution-logo-white-transparent.png';
import {Box, Button} from "@mui/material";

const Header = () => {
    return (
        <AppBar className={"appbar"} position="static">
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button className={"appbar-logo"}>
                    <img src={Logo} alt="Logo" style={{height: '95%'}}/>
                </Button>
                <Box/>
                <Box className={"appbar-title"}>
                    <Typography variant="h2" color="inherit" component="div" align="center">
                        Tabelltipset 2024
                    </Typography>
                </Box>
                <Box/>
                <Box/>
                <Box/>
            </Toolbar>
        </AppBar>
    );
};

export default Header;